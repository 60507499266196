import React from "react"
import { Link } from "gatsby"

const Copy = () => {
  const linkStyle = { fontSize: '0.8rem' };
  return (
    <>
      © {new Date().getFullYear()}, <strong>UptimeCatholic</strong>
      {` `}
      - <i>Sal 127, 1-2</i>
      {` | `}
      <Link
        style={linkStyle}
        to={`/politica-privacidad`}
      >
        Política de Privacidad
      </Link>
    </>
  );
}

export default Copy;
