import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

const oneWeek = new Date().getTime() + 24 * 7 * 3600 * 1000;

const CookieConsentBar = ({
  location,
}) => {
  const currentPath = location?.pathname || '';
  const rootPath = `${__PATH_PREFIX__}/`
  const privacyPageRegex = /politica-privacidad/;
  const isPrivacyPolicyPage = privacyPageRegex.test(currentPath);
  const isRootPath = location?.pathname === rootPath;
  const acceptOnScroll = !(isPrivacyPolicyPage || isRootPath);

  return (
    <CookieConsent
      location="bottom"
      sameSite="lax"
      expires={oneWeek}
      acceptOnScroll={acceptOnScroll}
      style={{
        fontSize: '0.8rem',
        backgroundColor: '#140A01',
      }}
      buttonStyle={{
        fontSize: '0.8rem',
        backgroundColor: '#e67509',
        color: '#fefefe',
      }}
    >
      Este blog usa cookies para mejorar la experienciad el usuario. Al utilizar el sitio, aceptas nuestras cookies. Te invitamos a leer nuestra <Link to={'/politica-privacidad'}>Política de Privacidad</Link>.
    </CookieConsent>
  );
};

export default CookieConsentBar;
