import React from "react"
import { Link, useStaticQuery } from "gatsby"

import CookieConsentBar from '../components/CookieConsentBar'

import { rhythm, scale } from "../utils/typography"

import Image from 'gatsby-image';

import Copy from './copy';

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(absolutePath: { regex: "/uptimecatholic_new_logotype.png/" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const { logo } = data;
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(0.4),
          color: '#000000',
          marginBottom: rhythm(1.5),
          marginTop: rhythm(1.5),
          textAlign: 'center',
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
            to={`/`}
          >
            <Image
              fixed={logo.childImageSharp.fixed}
              alt={title}
            />
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginBottom: rhythm(1.5),
          marginTop: rhythm(1.5),
          textAlign: 'center',
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <Image
            fixed={logo.childImageSharp.fixed}
            alt={title}
          />
        </Link>
      </h3>
    )
  }
  return (
    <>
      <div
        style={{
          backgroundColor: '#140A01',
          width: '100%',
          padding: '16px 0',
        }}
      >
        <header>{header}</header>
      </div>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: rhythm(2.5),
          }}
        >
          <Copy />
        </footer>
        <CookieConsentBar location={location} />
      </div>
    </>
  )
}

export default Layout
